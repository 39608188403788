import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <h2 className="footertext">CRLW</h2>{" "}
    </footer>
  );
};

export default Footer;
